import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Toast } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { sendemailPrivateBookingStars } from '@store/auth/authActions';
import { fetchAllCinemas } from '@store/cinemas/cinemasActions';
import Select from 'react-select';
import {
  useGetNowShowingQuery,
  useGetUpcomingQuery,
} from '@store/rtk/rtk.movieLists';
import {fetchMoviespharse} from '@store/movies/movieActions';
import Carousel from 'react-bootstrap/Carousel';
import Banner from '@assets/pictures/Banner_priv.jpg';
const PrivateScreenings = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [guestNo, setGuestNo] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [status, setStatus] = useState(false);
  const [allCinemasLoaded, setAllCinemasLoaded] = useState(false);
  const [selectedcity, setSelectedCity] = useState('');
  const [availableCinemas, setAvailableCinemas] = useState([]);
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [movieType, setMovieType] = useState('Now Showing');
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [errors, setErrors] = useState({});
  const [fnbRequirement, setFnbRequirement] = useState('');
  const [eventType, setEventType] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);


  const { pharsShowing, pharseShowingError, pharseShowingLoading } = useSelector((state) => ({
    pharsShowing: state.movies.pharsShowing, 
    pharseShowingError: state.movies.pharseShowingError,
    pharseShowingLoading: state.movies.pharseShowingLoading,
  }));


  useEffect(() => {
    dispatch(fetchMoviespharse());
  }, [dispatch]);

  // console.log("Movies Data:", pharsShowing);
  // console.log("Loading:", pharseShowingLoading);
  // console.log("Error:", pharseShowingError);

  const cinemas = useSelector((state) => state.cinemas);
  const { cinemasLoading, allCinemas } = cinemas;

  const citiesWithCinemas = {};

  allCinemas.forEach((cine) => {
    const cityName = cine.city_name;

    if (!citiesWithCinemas[cityName]) {
      citiesWithCinemas[cityName] = [];
    }

    citiesWithCinemas[cityName].push(cine);
  });

  const sortedCityOptions = Object.keys(citiesWithCinemas)
  .sort((a, b) => a.localeCompare(b))
  .map((city) => ({ value: city, label: city }));

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleCityChange = (e) => {
    const city = e.value;
    setSelectedCity(city);
    setAvailableCinemas(citiesWithCinemas[city] || []);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleMovieTypeChange = (e) => {
    setMovieType(e.value);
  };

  const handleGuestNoChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setGuestNo(value);
    }
  };

  // const handleLanguageChange = (e) => {
  //   setSelectedLanguage(e.target.value);
  // };

  const handleLanguageChange = (lang) => {
    if (lang) {
      setSelectedLanguage({ lang });
    } else {
      setSelectedLanguage(null);
    }
  };

  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });

    const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();

  useEffect(() => {
    if (!nowShowingLoading && todaysScreeningsMovies && pharsShowing) {
      // console.log('Today\'s Screenings Movies:', todaysScreeningsMovies);
      let filtered = [];

      if (movieType === 'Now Showing') {
        filtered = todaysScreeningsMovies.data.filter((movie) =>
          movie.cine_ids.split(',').includes(String(selectedCinema?.cinema_id)),
        );
      } else if (movieType === 'Star Library') {

        // const nowShowingFiltered = todaysScreeningsMovies.data.filter(
        //   (movie) => movie.d_company_name === 'Phars Film LLC',
        // );
        // const comingSoonFiltered = comingSoonMovies.data.filter(
        //   (movie) => movie.d_company_name === 'Phars Film LLC',
        // );

        const pharsFiltered = pharsShowing.map((movie) => ({
          ...movie,
        }));
        // filtered = [...nowShowingFiltered, ...comingSoonFiltered, ...pharsFiltered];
        filtered = [...pharsFiltered];

        // console.log('filtered:', filtered)

      }

      setFilteredMovies(filtered);

      setSelectedMovie(null);
    setAvailableLanguages([]);
    setSelectedLanguage('');
    }
  }, [
    todaysScreeningsMovies,
    comingSoonMovies,
    pharsShowing, 
    nowShowingLoading,
    comingSoonLoading,
    selectedCinema,
    selectedDate,
    movieType,
  ]);

  const handleMovieChange = (e) => {
    if (e) {
      // const movieId = e.target.value;
      const movieId = e.value;
      // console.log('movieId:', movieId)
      const movie = filteredMovies.find(
        (movie) => movie.movie_id === parseInt(movieId),
      );
      setSelectedMovie(movie);
      // console.log("movie", movie)

      // if (movie && movie.languages_with_translations) {
      //   const languages = movie.languages_with_translations
      //     .map((lang) => lang.lang_name)
      //     .filter((value, index, self) => self.indexOf(value) === index);
      //   setAvailableLanguages(languages);
      //   //   console.log("Available Languages:", languages);

      //   setSelectedLanguage(null);
      // } else {
      //   setAvailableLanguages([]);
      //   //   console.log("No languages available for the selected movie.");
      // }
      if (movie) {
        const translationLanguages = movie.languages_with_translations
          ? movie.languages_with_translations.map((lang) => lang.lang_name)
          : [];
        
        const concatenatedLanguages = movie.concatenated_languages
          ? movie.concatenated_languages.map((lang) => lang.lang)
          : [];
  
        const languages = [...translationLanguages, ...concatenatedLanguages]
          .filter((value, index, self) => self.indexOf(value) === index);
  
        setAvailableLanguages(languages);
        // console.log("Available Languages:", languages);
  
        setSelectedLanguage(null);
      } else {
        setAvailableLanguages([]);
        // console.log("No languages available for the selected movie.");
      }
    } else {
      setSelectedMovie(null);
      setAvailableLanguages([]);
    }
  };

  useEffect(() => {
    dispatch(fetchAllCinemas())
      .then((response) => {
        setAllCinemasLoaded(true);
      })
      .catch((error) => {
        console.log('error fetching cinemas', error);
      });
  }, [dispatch]);

  const today = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });

  const validateForm = () => {
    let formErrors = {};
    if (!fullName) formErrors.fullName = '* Full Name Is Required';
    if (!email) formErrors.email = '* Email Is Required';
    if (!phone) formErrors.phone = '* Phone Number Is Required';
    if (!selectedcity) formErrors.selectedcity = '* City Is Required';
    if (!selectedCinema) formErrors.selectedcity = '* Cinema Is Required';
    if (!selectedDate) formErrors.selectedDate = '* Date Is Required';
    if (!selectedTime) formErrors.selectedTime = '* Time Is Required';
    // if (!selectedMovie) formErrors.selectedMovie = '* Movie Selection is Required';
    if (!selectedMovie || selectedMovie === 'Select Movie') {
      formErrors.selectedMovie = '* Movie Selection is Required';
    }

    if (!guestNo || guestNo <= 0)
      formErrors.guestNo = '* Number Of Guest Is Required';
    if (!fnbRequirement)
      formErrors.fnbRequirement = '* F&B Requirement is required';
    if (!eventType) {
      formErrors.eventType = 'Please select an event type';
    }
    if (!selectedLanguage || selectedLanguage === 'Select Language') {
      formErrors.selectedLanguage = '* Language Is Required';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  let globalOptions = useSelector((state) => state.movies.globalOptions);
  // console.log('globalOptions:', globalOptions)
  const stc_id = 3;

  //     const stc_id = globalOptions.find((g)=>g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
  // )?.go_value ?? 0;

  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);
  // console.log('cinema:', cinema)
  const { loggesInUser } = auth;
  const customer_id = loggesInUser?.cust_id || null;
  let contact_no = loggesInUser?.cust_mobile || null;
  let cinema_id = cinema?.cinema_id || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      // console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.stylemarginBottom = '3px';
      }
    });
  });


const submitForm = (e) => {
  // console.log("formsubmit",
  //   {validateForm},
  //   {name: `User from Mycinema - ${fullName}`,
  //     email: email,
  //     contact_no: phone,
  //     customer_id: null,
  //     cinema_id: selectedCinema?.cinema_id,
  //     cinema_name:selectedCinema?.cinema_name,
  //     stc_id,
  //     city: selectedcity,
  //     guest_no: guestNo,
  //     preferred_date: selectedDate,
  //     preferred_time: selectedTime,
  //     movie_id: selectedMovie?.movie_id,
  //     movie_title: selectedMovie?.movie_title,
  //     language: availableLanguages.length > 0 ? availableLanguages[0] : null,
  //     fnb_requirement: fnbRequirement,
  //     event_type: eventType,
  //     movie_type: movieType,}
  // )
  e.preventDefault();
  setSubmitted(true);

  if (validateForm()) {
    setValidForm(true);

    dispatch(
      sendemailPrivateBookingStars({
        name: `User from Mycinema - ${fullName}`,
        email: email,
        contact_no: phone,
        customer_id: null,
        cinema_id: selectedCinema?.cinema_id,
        cinema_name:selectedCinema?.cinema_name,
        stc_id,
        city: selectedcity,
        guest_no: guestNo,
        preferred_date: selectedDate,
        preferred_time: selectedTime,
        movie_id: selectedMovie?.movie_id,
        movie_title: selectedMovie?.movie_title,
        language: availableLanguages.length > 0 ? availableLanguages[0] : null,
        fnb_requirement: fnbRequirement,
        event_type: eventType,
        movie_type: movieType,
      })
    ).then(() => {
      setStatus(true);
    });

    setShowSuccess(true);
    
    setTimeout(() => {
      setShowSuccess(false);
      setEmail('');
      setFullName('');
      setPhone('');
      setGuestNo('');
      setSelectedCity('');
      setSelectedCinema(null);
      setSelectedDate('');
      setSelectedTime('');
      setSelectedMovie(null);
      setAvailableLanguages([]);
      setSelectedLanguage('');
      setEventType(null);
    }, 3000);
  } else {
    setValidForm(false);
  }
};
  
  //   const handleCinemaChange = (event) => {
  //     setSelectedCinema(event.target.value);
  //   };
  return (
    <>
      <div>
        <Row className="text-center">
          <Col md={12}>
            <img className="d-block w-100" src={Banner} alt="banner" />
          </Col>
        </Row>
      </div>
      <Container className="mt-5">
        <Form onSubmit={submitForm}>
          <Row className="justify-content-center rounded">
            <Col md={6}>
              <Form.Group controlId="eventType">
                <Form.Label>Event Type</Form.Label>
                <Select
                  options={[
                    { value: 'Birthday', label: 'Birthday' },
                    { value: 'Corporate Event', label: 'Corporate Event' },
                    { value: 'Private Screening', label: 'Private Screening' },
                    { value: 'Workshop', label: 'Workshop' },
                    { value: 'Conference', label: 'Conference' },
                    { value: 'Training Program', label: 'Training Program' },
                  ]}
                  value={eventType ? { value: eventType, label: eventType } : null}
                  onChange={(selectedOption) =>
                    setEventType(selectedOption ? selectedOption.value : null)
                  }
                  isInvalid={submitted && !!errors.eventType}
                  placeholder="Select Event Type"
                />
                {submitted && errors.eventType && (
                  <div className="invalid-feedback">{errors.eventType}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your full name"
                  value={fullName}
                  onChange={handleFullNameChange}
                  isInvalid={submitted && !!errors.fullName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={submitted && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  isInvalid={submitted && !!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="emirate">
                <Form.Label>Select Emirate</Form.Label>
                <Select
                  options={sortedCityOptions}
                  value={
                    selectedcity
                      ? { value: selectedcity, label: selectedcity }
                      : null
                  }
                  onChange={handleCityChange}
                  isInvalid={submitted && !!errors.selectedCity}
                  placeholder="Select City"
                />
                {submitted && errors.selectedCity && (
                  <div className="invalid-feedback">{errors.selectedCity}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="selectCinema">
                <Form.Label>Select Cinema</Form.Label>
                <Select
                  options={availableCinemas.map((cinema) => ({
                    value: cinema.cinema_name,
                    label: cinema.cinema_name,
                  }))}
                  value={
                    selectedCinema
                      ? {
                          value: selectedCinema.cinema_name,
                          label: selectedCinema.cinema_name,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    setSelectedCinema(
                      availableCinemas.find(
                        (c) => c.cinema_name === selectedOption.value,
                      ),
                    )
                  }
                  isDisabled={!selectedcity}
                  isInvalid={submitted && !!errors.selectedCinema}
                  placeholder="Select Cinema"
                />
                {submitted && errors.selectedCinema && (
                  <div className="invalid-feedback">{errors.selectedCinema}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="movieType">
                <Form.Label>Movie Type</Form.Label>
                <Select
                  options={[
                    { value: 'Now Showing', label: 'Now Showing' },
                    { value: 'Star Library', label: 'Star Library' },
                  ]}
                  value={movieType ? { value: movieType, label: movieType } : null}
                  onChange={handleMovieTypeChange}
                  isInvalid={submitted && !!errors.movieType}
                  placeholder="Select Movie Type"
                />
                {submitted && errors.movieType && (
                  <div className="invalid-feedback">{errors.movieType}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="preferredDate">
                <Form.Label>Preferred Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedDate}
                  isInvalid={submitted && !!errors.selectedDate}
                  onChange={handleDateChange}
                  min={today}
                />
                <Form.Control.Feedback type="invalid">
                  {submitted && errors.selectedDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="preferredTime">
                <Form.Label>Preferred Time</Form.Label>
                <Form.Control
                  type="time"
                  value={selectedTime}
                  isInvalid={submitted && !!errors.selectedTime}
                  onChange={handleTimeChange}
                  min={selectedTime === today ? currentTime : null}
                />
                <Form.Control.Feedback type="invalid">
                  {submitted && errors.selectedTime}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="preferredMovie">
                <Form.Label>Preferred Movie</Form.Label>
                <Select
                  options={filteredMovies.map((movie) => ({
                    value: movie.movie_id,
                    label: movie.movie_title,
                  }))}
                  value={
                    selectedMovie
                      ? {
                          value: selectedMovie.movie_id,
                          label: selectedMovie.movie_title,
                        }
                      : null
                  }
                  onChange={handleMovieChange}
                  isInvalid={submitted && !!errors.selectedMovie}
                  placeholder="Select Movie"
                />
                {submitted && errors.selectedMovie && (
                  <div className="invalid-feedback">{errors.selectedMovie}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="preferredLanguage">
                <Form.Label>Preferred Language</Form.Label>
                <Select
                  options={availableLanguages.map((lang) => ({
                    value: lang,
                    label: lang,
                  }))}
                  value={
                    selectedLanguage
                      ? {
                          value: selectedLanguage.lang,
                          label: selectedLanguage.lang,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleLanguageChange(
                      selectedOption ? selectedOption.value : null,
                    )
                  }
                  isInvalid={submitted && !!errors.selectedLanguage}
                  placeholder="Select Language"
                />
                {submitted && errors.selectedLanguage && (
                  <div className="invalid-feedback">{errors.selectedLanguage}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="numberOfGuests">
                <Form.Label>Number of Guests</Form.Label>
                <Form.Control
                  type="number"
                  value={guestNo}
                  onChange={handleGuestNoChange}
                  isInvalid={submitted && !!errors.guestNo}
                  placeholder="Enter number of guests"
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                {submitted && errors.guestNo}
              </Form.Control.Feedback>
            </Col>
            <Col md={6}>
              <Form.Group controlId="fnbRequirement">
                <Form.Label>F&B Requirement</Form.Label>
                <Select
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                  ]}
                  onChange={(selectedOption) =>
                    setFnbRequirement(selectedOption.value)
                  }
                  placeholder="Select Requirement"
                />
                {errors.fnbRequirement && (
                  <div className="invalid-feedback">{errors.fnbRequirement}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          {/* <Form.Group controlId="copyToSelf">
            <Form.Check type="checkbox" label="Copy To Self" />
          </Form.Group> */}

          <Row className="justify-content-center mt-4">
            <Col md={6}>
              <Button variant="primary" type="submit" block style={{color:'white'}}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-center mt-3">
            <Toast
              onClose={() => setShowSuccess(false)}
              show={showSuccess}
              delay={300000000}
              autohide
              style={{
                position: 'absolute',
                marginTop: '10px',
                zIndex: 1000,
                backgroundColor:'#d4edda'
              }}
            >
              <Toast.Header>
                <strong className="me-auto">Success</strong>
              </Toast.Header>
              <Toast.Body>Your request has been submitted successfully!</Toast.Body>
            </Toast>
          </div>
      </Container>
      {/* <div>
           <Toast
                onClose={() => setShowSuccess(false)}
                show={showSuccess}
                delay={3000}
                autohide
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', 
                  backgroundColor: '#d4edda',
                  color: '#155724',
                  zIndex: 1050,
                  height:'200px',
                  width:'auto',
                }}
            >
                <Toast.Body>Form submitted successfully!</Toast.Body>
            </Toast>

      </div> */}

{/* <Toast
          onClose={() => setShowSuccess(false)}
          show={showSuccess}
          delay={30000000}
          autohide
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
            backgroundColor:'green'
          }}
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>Your request has been submitted successfully!</Toast.Body>
        </Toast> */}
    </>
  );
};

export default PrivateScreenings;
