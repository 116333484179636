import axios from 'axios';
import { BASEURL, TOKEN, PUBLICKEY } from '../config';
import { TokenService, CityService, PwaService } from './tokenService';
import { reactLocalStorage } from 'reactjs-localstorage';
import { decryptPayload, encryptPayload } from '@helper/index';
// import NodeRSA from 'node-rsa';

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: `${BASEURL}`,
  });

  let user_token = reactLocalStorage.get('user_token', null, true);
  if (!!user_token) {
    instance.defaults.headers.common['x-user-token'] = `${user_token}`;
  }

  if (TOKEN) {
    instance.defaults.headers.common['Authorization'] = `${TOKEN}`;
    instance.defaults.headers.common['x-web-security'] = true;
  }
  // instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  const isPwa = PwaService.getIsPwa();
  if (isPwa !== null || isPwa !== undefined) {
    instance.defaults.headers.common['ISPWA'] = isPwa;
  }

  instance.interceptors.response.use(
    function (response) {
      const keys = {
        key: process.env.REACT_APP_CRYPTO_KEY,
        iv: process.env.REACT_APP_CRYPTO_IV,
      };
      console.log({ keys });

      let decryptedData = decryptPayload(response.data.encryptedData, keys);

      try {
        // console.log(
        //   process.env.REACT_APP_PRIVATE_KEY,
        //   'privateKey',
        //   process.env,
        //   decryptedData,
        // );
        response.data = JSON.parse(decryptedData);
      } catch (error) {
        console.log(error, 'parsing failed on decryptedData');
        response.data = decryptedData;
      }
      return response;
    },
    function (error) {
      console.log('Error interceptors:', error);

      if (error.response && [401, 403].includes(error.response.status)) {
        TokenService.removeToken();
        TokenService.removeUser();
      }
      return Promise.reject(error);
    },
  );

  instance.interceptors.request.use(function (config) {
    const city_data = CityService.getCity();
    if (city_data) {
      const { cityId } = city_data;
      config.headers.CITY_ID = cityId;
    }

    if (config.data) {
      let tempData = config.data;
      try {
        tempData = JSON.parse(config.data);
      } catch (error) {}
      let parsedData = tempData;
      let updatedPayload = encryptPayload(
        {
          key: process.env.REACT_APP_CRYPTO_KEY,
          iv: process.env.REACT_APP_CRYPTO_IV,
        },
        JSON.stringify({ ...parsedData, isTheEncryptedText: true }),
      );
      config.data = { encryptedText: updatedPayload };
    }
    return config;
  });

  return instance;
};

export default createAxiosInstance;
