import React, { useState,useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Button from '@components/partials/Button';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { setShowLoginPopup } from '../../../store/auth/authActions';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import aboutUsImg1 from '@assets/pictures/about_us_1.jpeg';
import aboutUsImg2 from '@assets/pictures/about_us_2.jpeg';
import aboutUsImg3 from '@assets/pictures/about_us_3.jpeg';
import aboutUsImg4 from '@assets/pictures/about_us_4.jpeg';
import aboutUsImg5 from '@assets/pictures/about_us_5.jpeg';
import aboutUsImg6 from '@assets/pictures/about_us_6.jpeg';

// import aboutUsMap from '@assets/pictures/about_us_new_map.jpeg';
import aboutUsMap from '@assets/pictures/Location Map.png';

const AboutUsHeroText = (props) => {
  const { t, classes = '' } = props;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [register, setRegister] = useState(false);
  const [cms_pages_data, setcms_pages_data] = useState([]);

  const dispatch = useDispatch();
  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };

  const MspagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await GenericService.GetMsPage(1);
      const { data } = response;
      if (data?.status) {
        console.log("records=>",data.Records)
        setcms_pages_data(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function modifyHtmlContent(content) {
    let originalContent = content;
  
    // Create a temporary DOM element to manipulate the content
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = originalContent;
  
    // Remove all <img> tags and store the first one
    const images = tempDiv.getElementsByTagName('img');
    let firstImage = null;
    let remainingImagesHtml = '';
  
    while (images.length > 0) {
      if (!firstImage) {
        // Add class "about-us-map" to the first <img> tag
        images[0].classList.add('about-us-map');
        firstImage = images[0].outerHTML; // Store the modified <img> tag
      } else {
        // Wrap remaining images in divs and store them
        remainingImagesHtml += `
          <div class="col-xl-3 col-md-4">
            ${images[0].outerHTML}
          </div>
        `;
      }
      images[0].remove(); // Remove the <img> tag
    }
  
    // Prepare the main layout
    let mainLayout = `
    <div class="row">
      <section class="content-box col-xl-4 col-md-12">
        ${tempDiv.innerHTML} <!-- Append remaining content here -->
      </section>
      <section class="image-box col-xl-8 col-md-12">
        ${firstImage ? firstImage : ''} <!-- Append first image here -->
      </section>
    </div>
<hr class="border-dark" />
    <div class="row achievments_wrapper justify-content-center">
      ${remainingImagesHtml} <!-- Append remaining images here -->
    </div>
    `;
  
    return mainLayout; // Return the modified HTML
  }
  
  

  useEffect(() => {
    dispatch(MspagesDataFunc());
  }, []);

  let tnc_data = cms_pages_data?.map((x) => {
    return x.page_content;
  });

  let data=modifyHtmlContent(tnc_data)

  return (
    <>
      <section className={`container-fluid about_us_hero_text ${classes}`}>
        <div className="row">
        <article className="col-lg-10 col-xl-12 mx-auto">
        <div
              className="mx-2"
              dangerouslySetInnerHTML={{
                __html: data?.toString(),
              }}
            />
          </article>

      
         { false && <article className="col-lg-10 col-xl-12 mx-auto">
            {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
            <CenteredTitle firstHalf={'WHO WE ARE'} secondHalf={''} />
          
            <div className="row">
              <section className="col-xl-4 col-md-12">
                <span
                  className={`f-14 ${isMobile ? 'ml-0' : ''}`}
                  style={{ margin: '0px' }}
                >
                  Theater Chain based in UAE
                </span>
                <p
                  className={`about_us_hero_text_synopsis ${
                    isMobile ? 'mb-3 mt-2' : ''
                  }`}
                  style={{ margin: '12px 5px' }}
                >
                  We are UAE's premier theatre chain, with more than 100 screens in
                  all over UAE, showcasing a wide range of films from Hollywood to
                  Bollywood, as well as local Emirati films, and films from around
                  the world, with contemporary food and beverage services. We are
                  committed to use the power of entertainment to create
                  family-oriented experiences and destinations that are innovative,
                  compelling and commercially viable.
                </p>
                <span
                  className={`f-14 ${isMobile ? 'ml-0 mb-2' : ''}`}
                  style={{ margin: '0px' }}
                >
                  Distributors & Exhibitors{' '}
                </span>
                <p
                  className={`about_us_hero_text_synopsis ${
                    isMobile ? 'mb-3 mt-2' : ''
                  }`}
                  style={{ margin: '12px 5px' }}
                >
                  We are operated by PHF (Phars Film), which has started in 1964 and
                  has since become the Middle East’s market leader in film
                  distribution and cinema exhibition. PHF unequaled distribution
                  network and stakeholder partnerships now oversee the regional
                  distribution and screening of over 200 films each year.
                </p>
              </section>

              <section className="col-xl-8 col-md-12">
                <img className="about-us-map" src={aboutUsMap}></img>
              </section>
            </div>
            <hr className="about_us_hr" />
            <div className="row achievments_wrapper justify-content-center">
              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg1} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg2} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg3} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg4} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg5} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg6} />
              </div>
            </div>
          </article>}
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHeroText);
