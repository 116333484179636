export const API_VERSION = "v1.0";
let HOST_URL = window.location.host
let backend_url = ''
if (HOST_URL == 'www.starcinemas.ae') {
  backend_url = 'https://web-api.starcinemas.ae'
}
else if (HOST_URL == 'starcinemas.ae') {
  backend_url =  'https://web-api.starcinemas.ae'
}
else {
  backend_url = 'localhost'
}
export const BASEURL_BRIJ = backend_url

//const HOST_URL = `xanadu-mycinema.brij.tech`;
//const LIVEBASEURL = `https://xanadu-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL = backend_url;
//  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = `https://d3kkcg1ju340qs.cloudfront.net`;
export const STARCINEMAS_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6Ijc1ZDAzZDQwLTJiYTQtNDk5ZC04ZGU0LTY4NTQ4ODIzZDUxNSIsImlhdCI6MTY4MjU0MTUyOX0.kutbXGZb1pQsxymG2dKqopjNcthIuYoexIVfL9RYpNY`;
export const TOKEN = STARCINEMAS_TOKEN;
