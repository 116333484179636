import React, { useState, useEffect, useCallback } from 'react';

const mobileWidth = 400;

function useIsSmallScreen() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < mobileWidth);

  const resizeEventHandler = useCallback(() => {
    setIsSmallScreen(window.innerWidth < mobileWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeEventHandler);

    return () => window.removeEventListener('resize', resizeEventHandler);
  }, [resizeEventHandler]);

  return isSmallScreen;
}

export default useIsSmallScreen;
