// import React from 'react';
import { withNamespaces } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import { Row, Container, Col, Dropdown, Modal, Alert, Button } from 'react-bootstrap';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsSmallScreen from '@src/customHooks/useIsSmallScreen'
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import SpinnerCompo from '../components/partials/Spinner';
import seatsImg from '@assets/pictures/untitled-12.jpg';
import seatsImg1 from '@assets/pictures/untitled-22.jpg';
import seatsImg2 from '@assets/pictures/untitled-41.jpg';
import MovieService from '@apiService/MovieService';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import TrailerIcon from '@assets/pictures/svgs/play_new_exp.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { BASEURL } from '../config/index';
import { getSeatIconUrl } from '@src/helper';
import useIsTablet from '@src/customHooks/useIsTablet';
import { getAvailablePromotionsOffers } from '../store/movies/movieActions';
const Offers = ({ t }) => {
  const { t1, banners, isLoading, showOnly = null } = t;
  const isMobile = useIsMobile();
  const isSmallScrenn = useIsSmallScreen();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  const { pathname } = useLocation();
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [OfferPagesData, setOfferPagesData] = useState([]);
  const [uniqueOgtTypeName, setUniqueOgtTypeName] = useState([]);
  const [selectedOfferType, setSelectedOfferType] = useState('All');
  const [offerdiv, setofferdiv] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  

  const OfferPagesDataFunc = () => async (dispatch) => {
    try {
      const response = await MovieService.GetaActivePromotionAndOffersNew();
      const { data } = response;
      // console.log('response:', response);
      if (data?.status) {
        setOfferPagesData(data.offerData);

        let ogtTypeName = new Set();
        data.offerData.forEach((offer)=>{
          offer.offerContent.forEach((content)=>{
            if(content.ogt_type_name) {
              ogtTypeName.add(content.ogt_type_name)
            }
          });
        });
        const uniqueOgtTypeArray = Array.from(ogtTypeName);
        uniqueOgtTypeArray.unshift('All'); 
        uniqueOgtTypeArray.sort((a, b) => a.localeCompare(b)); 
        setUniqueOgtTypeName(uniqueOgtTypeArray);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    dispatch(OfferPagesDataFunc());
  }, []);

  let banner_arr = [];
  OfferPagesData?.map((x) => {
    return x?.offerContent?.map((elem) => {
      banner_arr.push(elem?.offer_banner_image);
    });
  });

  let filtered_bannerarr = banner_arr?.filter((y) => y);

  let poster_banner = [];
  OfferPagesData?.map((x) => {
    return x?.offerContent?.map((elem) => {
      poster_banner.push(elem?.offer_poster_image);
    });
  });
  const isTablet = useIsTablet();
  const [swiping, setSwiping] = useState(false);
  const bannerSlider = {
    pauseOnFocus: true,
    onSwipe: () => setSwiping(true),
    arrows: false,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const filteredOffers = selectedOfferType === 'All' 
    ? OfferPagesData 
    : OfferPagesData.filter(offer => 
        offer.offerContent.some(content => content.ogt_type_name === selectedOfferType)
      );
      // console.log("filteredOffers", filteredOffers);

      const handleShowModal = (offer) => {
        // console.log('Offer clicked:', offer);
        setSelectedOffer(offer); 
        setShowModal(true); 
        // console.log('Modal State:', showModal);
      };
    
      const handleCloseModal = () => {
        setShowModal(false); 
        setSelectedOffer(null);
      };

  return (
    <>
      <div className="offer_main_wrapper">
        {/* <div className="left_right_align">
          <CenteredTitle
            firstHalf={`${'Offers'}`}
            secondHalf={''}
          />
        </div> */}

        {/* <Slider
          {...bannerSlider}
          className="home_banner"
          style={{ marginBottom: '0px', marginLeft: '0px !important' }}
        >
          {filtered_bannerarr.length > 0 &&
            filtered_bannerarr?.map((x) => {
              return (
                <>
                  {x && (
                    <img
                      style={{ cursor: 'pointer', width: '100%' }}
                      src={getSeatIconUrl(x)}
                      className="img-fluid m-auto"
                    />
                  )}
                </>
              );
            })}
        </Slider> */}

        {/* <section className="container-fluid container-xl mt-3">
          <h5 className="font-weight-bold">Cinema Offer !!!</h5>
          <p>
            To get our best offers all you need to do is save this page or download
            the Star Cinemas application and stay tuned.
          </p>
        </section> */}
          <div className='tab_section'>
          <section className="ments_new">
            {uniqueOgtTypeName && uniqueOgtTypeName.length > 0 && (
              <div className="tab_section_div">
                {uniqueOgtTypeName.map((ogt_type_name, index) => (
                    <button
                      key={index}
                      className={`ogt-type-btn ${
                        index === 0 && !selectedOfferType ? 'selected' : ''
                      } ${
                        selectedOfferType === ogt_type_name ? 'selected' : ''
                      }`}
                      onClick={() => setSelectedOfferType(ogt_type_name)}
                    >
                      {ogt_type_name}
                    </button>
                  ))}
              </div>
            )}
            {!uniqueOgtTypeName.length && <p>No Offers Available</p>}
          </section>
          </div>

<section className="container-fluid container-xl offer_poster_wrapper">
  <div className="mt-4">
    <Row>
    {filteredOffers?.map((t, index) =>
      t?.offerContent?.map((x, subIndex) => (
        <Col xs={6} sm={4} md={4} lg={3} key={`${index}-${subIndex}`} className="mb-4 gap-3">
        <div className="card rounded smallcard" style={{ height:(isMobile || isTablet)?"286px" :"356px" }}>
          <img className="card-img-top custom-img-fluid m-auto" src={getSeatIconUrl(x?.offer_poster_image)} alt={getSeatIconUrl(x?.offer_poster_image)} onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = dummyPosterStatic;
                                }}
                                 />
          <div className="card-body">
            {!isMobile &&(
            <h6 className="card-title font-weight-bold">{x.offer_title}</h6>
          )}
          {isMobile &&(
            <p className='font-weight-bold' style={{fontSize:'14px', marginLeft:'-8px'}}>{x.offer_title}</p>
          )}
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2 p-2'>
          <p className={`${(isMobile || isSmallScrenn) ? "valid_date2" : "valid_date"} ml-2`} style={{width:isMobile || isTablet ? '80px':'',}}>Valid till:{moment(x.offer_end_date_time).format('ddd, MMM DD, YYYY')}</p>
          <button type="button" class="btn btn-warning"
          onClick={() => handleShowModal(x)}
          >
            View
          </button>
          </div>
        </div>
        </Col>
      ))
    )}
    </Row>

    <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="tnc_name_heading">{selectedOffer?.offer_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="container-fluid">
      <Row>
        {/* Left side: Description */}
        <Col md={7}>
            {/* <p className="tnc_name_heading my-3">
              {selectedOffer?.offer_title}
            </p> */}
            <div
              dangerouslySetInnerHTML={{
                __html: selectedOffer?.offer_description ? selectedOffer?.offer_description : '',
              }}
              className="offer_description_wrapper"
            ></div>

            {selectedOffer?.offer_redeem_at_boxoffice === 'Y' &&
              selectedOffer?.offer_redeem_at_online === 'N' &&
              !selectedOffer?.offer_description && (
                <div className="offer_description_wrapper">
                  <p>This Offer is Applicable Only on BoxOffice</p>
                </div>
              )}
              </Col>
              <Col md={5} className="text-center">
          <img
            src={getSeatIconUrl(selectedOffer?.offer_poster_image)}
            alt={selectedOffer?.offer_title}
            className="img-fluid rounded"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = dummyPosterStatic;
            }}
            style={{ maxHeight: "300px", objectFit: "cover" }}
          />
        </Col>
      </Row>
    </div>
          </Modal.Body>
          <Modal.Footer>
                  <div className='d-flex justify-content-between align-items-center w-100'>
                      <p className={`${(isMobile || isSmallScrenn) ? "valid_date2" : "valid_date"} ml-2 mb-0`}>
                        Valid till: {moment(selectedOffer?.offer_end_date_time).format('ddd, MMM DD, YYYY')}
                      </p>
                      
                      <Button variant="secondary" onClick={handleCloseModal} className="ml-auto">
                        Close
                      </Button>
                  </div>
          </Modal.Footer>

        </Modal>
  </div>
</section>

      </div>
    </>
  );
};
export default withNamespaces()(Offers);
//width:isSmallScrenn?"10rem":isMobile?"12rem":isTablet?"14rem":"18rem",