import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { sendCareerMailStar } from '@store/auth/authActions';
import BreadCrumb from '@components/partials/BreadCrumbs';
import MovieShow from './partials/movieShow.jsx';
import { HOST_URL, BASEURL } from '../config/index';
import ShowDetailsPopup from './partials/popups/showDetailsPopup.jsx';
import GenericService from '@apiService/GenericService';

import SeatTypePopup from './partials/popups/SeatTypePopup.jsx';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import CenteredTitle from '@components/partials/CenteredTitle';
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from 'react-bootstrap';
import Slider from 'react-slick';

function AdvertiseForm({ t }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(``);
  const [name, setName] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [company, setCompany] = useState(``);
  const [salary, setSalary] = useState(0);
  const [visa, setVisa] = useState(``);
  const [experience, setExperience] = useState(``);
  const [suggestions, setSuggestions] = useState(``);
  const [cinemaname, setCinemaname] = useState(``);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [FileName, setFileName] = useState('');
  const [ImageURL, setImageURL] = useState('');
  const { pathname } = useLocation();
  const cinemas = useSelector((state) => state.cinemas);
  const { cinemasLoading, allCinemas } = cinemas;
  const formRef = useRef(null);
  const [cms_pages_data, setcms_pages_data] = useState([]);


  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const MspagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await GenericService.GetMsPage(7);
      const { data } = response;
      if (data?.status) {
        console.log("records=>",data.Records)
        setcms_pages_data(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(MspagesDataFunc());
  }, []);

  let tnc_data = cms_pages_data?.map((x) => {
    return x.page_content;
  });

  useEffect(() => {
    dispatch(fetchAllCinemas());
  }, [dispatch]);
  const isBookEvent = pathname === '/book-event';

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const stc_id =
    globalOptions.find(
      (g) => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
    )?.go_value ?? 0;
  console.log({ stc_id });

  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);
  const { loggedInUser } = auth;
  let customer_id = loggedInUser?.cust_id || null;
  let contact_no = loggedInUser?.cust_mobile || null;
  let cinema_id = cinema?.cinema_id || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.style.marginBottom = '3px';
      }
    });
  });

  const uploadFile = (e) => {
    let errMsg = '';
    let image = e.target.files[0];
    let url = `${BASEURL}/uploadfileContactUs`;
    setFileName(image.name);
    let formdata = new FormData();
    formdata.append('file', image);
    axios
      .post(url, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        const { data } = response;
        console.log({ data }, `${data.imageBaseURL}${data.path}`);

        if (data.status) {
          console.log(`${data.imageBaseURL}${data.path}`);
          // self.ImageURL = `${data.imageBaseURL}${data.path}`;
          setImageURL(data.fullpath);

          let i = 0;
          if (i == 0) {
            i = 1;
            let elem = document.getElementById('myBar');
            let width = 1;
            let id = setInterval(frame, 10);
            function frame() {
              if (width >= 100) {
                clearInterval(id);
                i = 0;
              } else {
                width++;
                elem.style.width = width + '%';
              }
            }
          }
        } else {
          toast.error(data.message || 'Upload Error');

          // self.$root.$emit("bv::show::modal", "errorModal");
        }
      })
      .catch(function (error) {
        toast.error(error);

        console.log(error);
      });
  };
  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (name && mobile && email && salary && visa && FileName && experience) {
      setValidForm(true);
    }
  };

  useEffect(() => {
    if (validForm) {
      dispatch(
        sendCareerMailStar({
          name: name,
          email,
          contact_no: mobile,
          organization_name: company,
          message: suggestions,
          selectedcinema_name: cinemaname,

          uploadedFilePath: ImageURL,
          salary: salary,
          visa: visa,
          experience: experience,
        }),
      ).then(() => {
        setStatus(true);
      });
    }
  }, [validForm]);

  useEffect(() => {
    if (status) {
      setEmail('');
      setName('');
      setMobile('');
      setCompany('');
      setCinemaname('');
      setSuggestions('');
      setFileName('');
      setSalary(0);
      setVisa('');
      setExperience('');
    }
  }, [status]);

  return (
    <>
      {/* <div className="contact_breadcrumb">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Contact Us')} />
      </div> */}
      <div className="header-space container-fluid container-xl feedback-container">
        {/* <motion.p
          className="title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          {pathname === "/book-event" ? "Booking" : "Contact"}
        </motion.p> */}
        <div className="mainContainer">
        <div
              className="mx-2"
              dangerouslySetInnerHTML={{
                __html: tnc_data?.toString(),
              }}
            />
      <div className='btn_container mt-4'>
            <div className='avilable_btn'>
              <Link to="/jobspage">
                <h4><button>Available Positions</button></h4>
              </Link>
            </div>
            <div className='apply_btn mt-5'>
              <Link to="/applyform">
                <h4><button >Apply Here</button></h4>
              </Link>
            </div>
          </div>
        </div>
       { false && <div className="mainContainer">
          <div className="headind_first">
            <h2 className="heading_tag">Careers At Star Cinemas</h2>
          </div>
          <div className='paragraph_first mt-4 text-justify'>
            <p className='paragraph_tag mt-3 text-sm'>We are currently refining our strategy with the aim of becoming a UAE recognized and respected company.</p>
            <p className='paragraph_tag mt-3'> We seek individuals who possess enthusiasm, energy, flexibility, and a team-oriented mindset. As a company in rapid growth, we value passion and the ability to thrive in challenging situations. Additionally, customer service excellence is a top priority for us, so we highly value individuals who provide exceptional service to our valued customers
            </p>
          </div>
          <div className="heading_second mt-4 text-justify">
            <h5 className="heading_second_tag">How We Work</h5>
            <p className="paragraph_tag mt-3">
              Our staff typically work over six days a week, with a mixture of day
              and night shifts that may include weekends. Our teams are made up of
              multi-skilled individuals who are proficient in all departments i.e.
              serving customers at the concession area or assisting them from the
              ticket counters.{' '}
            </p>
            <p className="paragraph_tag mt-3">
              Our Dubai head office is home to a range of corporate functions from
              Operations, Marketing, HR, IT, Finance, Business Development and
              Project Management. Our operating hours are from 9:00 am to 6:00 pm
              Monday to Friday.
            </p>
          </div>
          <div className="mt-4">
            <h6 className="heading_third_tag">Perks and Benefits:</h6>
            <div className="list_items mt-3 text-justify">
              <ul className="list_items_unorder">
                <li className="list_items_lists mt-3">
                  Our staff are covered by insurance policy with amazing benefits.
                </li>
                <li className="list_items_lists mt-3">
                  Air ticket to your home country after one year of service
                </li>
                <li className="list_items_lists mt-3">
                  Cinemas staff will enjoy a variety of activities and challenges
                  including quarterly awards and additional allowances.
                </li>
                <li className="list_items_lists mt-3">Amazing environment</li>
              </ul>
            </div>
          </div>
          <div className="questins_head mt-4 text-justify">
            <p className="paragraph_tag mt-3">
              Do you talk movies? Are you a team player & looking for an exciting
              career?
            </p>
            <div className="mt-3 text-justify">
              <p className="paragraph_tag">
                We are growing and constantly seeking candidates with talent and
                passion. So, If you’re looking to inspire, be inspired and work at
                the intersection of entertainment and movies, come and join our team
                of Star Cinemas.
              </p>
            </div>
          </div>
          <div className='btn_container mt-4'>
            <div className='avilable_btn'>
              <Link to="/jobspage">
                <h4><button>Available Positions</button></h4>
              </Link>
            </div>
            <div className='apply_btn mt-5'>
              <Link to="/applyform">
                <h4><button >Apply Here</button></h4>
              </Link>
            </div>
          </div>
        </div>}
        <div className="container-fluid align_new text-center">
          {/* <BreadCrumb firstHalf={`${t('Home')} >`} secondHalf={t('Location')} /> */}
          {/* <CenteredTitle firstHalf={t('Career')} secondHalf={''} /> */}
          {/* <h1 className="mb-0 font-weight-bold">{t('Career Form')}</h1>*/}
        </div>
        {/*<motion.div
          className="feedback"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.75 }}
        >
          {status && (
            <div className="email-success-message">
              {t('Message sent successfully')}{' '}
            </div>
          )}

          <section className="container-fluid container-xl mt-3 location_wrapper" ref={formRef}>

          </section>
          </motion.div>*/}
      </div >
    </>
  );
}

export default withNamespaces()(AdvertiseForm);
