import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import PageService from '@apiService/PageService.js';


// Components
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle,
  Card,
} from 'react-bootstrap';
import CenteredTitle from '@components/partials/CenteredTitle';
import { useSelector } from 'react-redux';
import BreadCrumb from '@components/partials/BreadCrumbs';
import searchIcon from '@assets/pictures/svgs/search.svg';
import close from '@assets/pictures/svgs/close.svg';
import InlineScroll from '@components/partials/InlineScroll';
import useIsMobile from '../customHooks/useIsMobile';
const FAQNewContainer = ({ t }) => {
  const selected_language = useSelector((state) => state.movies.selected_language);
  const isMobile = useIsMobile();
  const lengthOfFaqsArray = 10;
  const [activeAccordionTab, setActiveAccordionTab] = useState(
    [...new Array(lengthOfFaqsArray)].map((item, index) => (index ? false : true)),
  );
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState('0');
  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = Array(lengthOfFaqsArray).fill(false);
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null);
      newActiveAccordionTab[index] = false;
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true;
    }
    setActiveAccordionTab(newActiveAccordionTab);
  };

  let [faqs, setFaqs] = useState([]);
  let [filteredFaq, setFilteredFaq] = useState([]);
  let [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);


  useEffect(() => {
    let faqs = [];
    let categories = new Set();
    // for (i = 0; i < 8; i++){

    // }

    // for (let i = 1; i < 8; i++) {
    let faq = {
      // q:'What if I received an error when trying to buy tickets?',
      // t(`FAQ.Q${i}`),
      // a: `We apologise for the inconvenience you have encountered with our online service.
      //    Before re- attempting your purchase, we recommend that you check your order status
      //     at www.starcinemas.ae/Theatre/Orderstatus.aspx.The credentials to login this page
      //     were emailed to you at your first booking attempt.If an order is placed, the order
      //      status will appear on the page just after you sign in.If no order appears in your Order History,
      //      we recommend that you then re - attempt your purchase.If you have questions regarding the
      //      purchase email our support team at customercare @starcinemas.ae`,
      // q: "hi",
      //     a:"hello",
      // t(`FAQ.A${i}`),
      // category: 'General',
      // categoryDisplay: t('General'),
    };

    // if (i >= 12 && i <= 16) {
    //   faq.category = 'On Demand';
    //   faq.categoryDisplay = t('On Demand');
    // } else if (i >= 16 && i <= 21) {
    //   faq.category = 'About Cinema';
    //   faq.categoryDisplay = t('About Cinema');
    // }

    faqs.push(faq);
    categories.add(
      JSON.stringify({
        category: faq.category,
        categoryDisplay: faq.categoryDisplay,
      }),
    );
    // }

    setFaqs(faqs);
    setFilteredFaq(faqs);
    setCategories(Array.from(categories.map((c) => JSON.parse(c))));
  }, [selected_language]);

  let [search, setSearched] = useState('');
  let [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    var acc = document.getElementsByClassName('accordion');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }
      });
    }
  });
  useEffect(() => {
    let filteredBySearch = [...faqs];

    if (!!selectedCategory) {
      filteredBySearch = filteredBySearch.filter((f) => {
        return f.category == selectedCategory;
      });
    }

    if (!!search) {
      filteredBySearch = filteredBySearch.filter((f) => {
        if (f.q.toLowerCase().includes(search.toLowerCase())) return true;
        else if (f.a.toLowerCase().includes(search.toLowerCase())) return true;

        return false;
      });
    }

    setFilteredFaq(filteredBySearch);
  }, [faqs, search, selectedCategory]);

  const getData = () => {
    let payload = {
        mpm_name: 'FAQS',
        mpm_lang_id: 1,
    };

    PageService.GetPageData(payload)
        .then((r) => {
            console.log('response=>', r.data);
            if (r.data && r.data.status) {    
                setData( r.data.Records);
            }
        })
        .catch((err) => {
            console.log('err=>', err);
        });
      }
  useEffect(() => {
    getData();
  }, []);



  return (
    <>
      <style>
        {`.accordion {
            background-color: white;
            color: #444;
            cursor: pointer;
            // padding: 18px;
            width: 100%;
            border: none;
            text-align: left;
            outline: none;
            font-size: 15px;
            transition: 0.4s;
            font-weight:bold;
            // border:1px solid #1f1c3c;
            // margin-bottom:5px;
          }
          .active,.accordion:hover {
            font-weight:bold;
          }
          .panel {
            padding: 10px 18px;
            display: none;
            background-color: white;
            overflow: hidden;
            // border:1px dotted #1f1c3c;
            // margin-bottom:5px;
            // margin-top:-5px;
          }
       
            .accordion:after {
              background:#1f1c3c;
              padding:3px 8px;
              border-radius:100%;
               content: '${'\\002B'}';
              // background: url('../../pictures/svgs/accordion_plus.svg');
              //  content: url('../../pictures/svgs/accordion_plus.svg');
              //  color:black !important;
               color: white;
              font-weight: bold;
              float: right;
              // margin-left: 5px;
          }
          .active:after {
            content:'${'\\2212'}';
            // content: url('../src/assets/pictures/svgs/accordion_minus.svg');
          }`}
      </style>

      <div className="container-fluid faq_new align_new">
        <section className="row">
          <div
            className="dsdss"
            style={{
              color: 'red',
              backgroundImage: `url(
                "data:image/svg+xml",
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="blue"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="green" />
                  <path
                    d="M7 11L13 17L19 11"
                    stroke="#D4307D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )`,
            }}
          ></div>

          <div className={isMobile ? 'left_right_align' : ''}>
            <BreadCrumb firstHalf={`${t('More')} >`} secondHalf=" FAQ’s" />
            <p className="faq_title">Frequently Asked Questions</p>
            {/*<CenteredTitle firstHalf={t("Frequently Asked Questions")} secondHalf=""/>*/}
          </div>
          <article className="col-lg-10 col-xl-8 mx-auto">
            <label className="fnb-search mb-4" style={{ width: '80%' }}>
              <img src={searchIcon} alt="search" onClick={(e) => {}} />
              <input
                type="text"
                placeholder={t('Search for concessions')}
                value={search}
                onChange={(e) => {
                  setSearched(e?.target?.value || '');
                }}
                style={{ borderRadius: '38px' }}
              />
              <img
                src={close}
                alt="close"
                className={`${!!search ? 'show' : ''}`}
                onClick={() => {
                  setSearched('');
                  setSelectedCategory('');
                }}
              />
            </label>
            {/* <div>
              <InlineScroll>
                {categories.map((c, i) => (
                  <button
                    key={i}
                    className={`faq_btn${
                      c.category === selectedCategory ? '' : '_inactive'
                    }`}
                    onClick={() => {
                      if (c.category === selectedCategory) setSelectedCategory('');
                      else setSelectedCategory(c.category);
                    }}
                  >
                    {c.categoryDisplay}
                  </button>
                ))}
              </InlineScroll>
            </div> */}
            {
              data.length>0 && data.map((ele)=>(
                <>
                   <button class="accordion">
                {
                  ele.mpm_d_main_name ||"-"
                }
            </button>
            <div class="panel">
              <p className="para-text-size">
                  {
                    ele.mpm_d_description ||"-"
                  }
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
                </>
              ))
            }

            <button class="accordion">
              What if I received an error when trying to buy tickets?
            </button>
            <div class="panel">
              <p className="para-text-size">
                We apologise for the inconvenience you have encountered with our
                online service. Before re-attempting your purchase, we recommend
                that you check your order status at
                www.starcinemas.ae/Theatre/Orderstatus.aspx. The credentials to
                login this page were emailed to you at your first booking attempt.
                If an order is placed, the order status will appear on the page just
                after you sign in. If no order appears in your Order History, we
                recommend that you then re-attempt your purchase. If you have
                questions regarding the purchase email our support team at
                customercare@starcinemas.ae
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
            <button class="accordion">
              What if I did not receive the Order Confirmation?
            </button>
            <div class="panel">
              <p className="para-text-size">
                If you do not receive a confirmation number (in the form of a
                confirmation page or email or SMS) after submitting payment
                information, or if you experience an error message or service
                interruption after submitting payment information, it is your
                responsibility to confirm with us whether or not your order has been
                placed. Only you may be aware of any problems that may occur during
                the purchase process. We will not be responsible for losses
                (monetary or otherwise) if you assume that an order was not placed
                because you failed to receive confirmation.
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
            <button class="accordion">Do Children need a movie tickets?</button>
            <div class="panel">
              <p className="para-text-size">
                Tickets are required by law for children who are 3 years of age and
                above. Children below 3 may not be admitted at certain cinemas
                please the specific cinema page.
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
            <button class="accordion">What is your refund policy?</button>
            <div class="panel">
              <p className="para-text-size">
                Star Cinemas has a no refund or exchange policy.
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
            <button class="accordion">Do you have a seating policy?</button>
            <div class="panel">
              <p className="para-text-size">
                Yes. All locations and shows have an allocated seating policy. We
                strongly advise our patrons to select their desired seats and ensure
                to remain in their allocated seats during the feature.
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
            <button class="accordion">
              I have purchased tickets online, What do I do now?
            </button>
            <div class="panel">
              <p className="para-text-size">
                {' '}
                Please print or carry the confirmation with you to the respective
                cinema box office 15 minutes before the show is scheduled to start
                and you will be issued physical tickets.
              </p>
            </div>
            <hr style={{ borderBottom: '1px solid #1f1c3c' }} />
            <button class="accordion">
              What are the different types of Movie Ratings?
            </button>
            <div className="panel">
              <p className="para-text-size">
                <span style={{ paddingBottom: '10px' }}>
                  These are the details for each rating, as stated by the Ministry
                  of Information and Culture.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>18TC </span>
                <span style={{ paddingTop: '5px' }}>
                  - Rated 18+ until confirmed by the National Media Council. Entry
                  to the cinema must comply with the age restriction of final
                  confirmed rating. Booking prior to confirmed rating, is at
                  customers discretion.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>18+ </span>
                <span style={{ paddingTop: '5px' }}>
                  - No persons under 18 years of age will be admitted. Babies not
                  allowed.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>15+ </span>
                <span style={{ paddingTop: '5px' }}>
                  - No persons under 15 years of age will be admitted. Babies not
                  allowed.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>PG 15</span>
                <span style={{ paddingTop: '5px' }}>
                  - Customer may be aged 15 years and under, but must be accompanied
                  by someone 15 years old or older. Please note content may not be
                  appropriate for 15 years and below, booking to be at the
                  discretion of guardians and parents.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>PG 13</span>
                <span style={{ paddingTop: '5px' }}>
                  -Customer may be aged 13 years and under, but must be accompanied
                  by someone 13 years old or older. Please note content may not be
                  appropriate for 13 years and below, booking to be at the
                  discretion of guardians and parents.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>PG</span>{' '}
                <span style={{ paddingTop: '5px' }}>
                  - Parental Guidance Advised.
                </span>
                <br />
                <span style={{ color: '#ee15fc', paddingTop: '5px' }}>G</span>
                <span style={{ paddingTop: '5px' }}>
                  {' '}
                  - General Exhibition - open for all ages.
                </span>
              </p>
            </div>
            {/* <Accordion
             activeKey={activeAccordionTabIndex}
             defaultActiveKey={activeAccordionTabIndex}
             className="faq_new_accordion"
            >
              <Card>
              <Accordion.Toggle
                  as={Card.Header}
                  // className={`${activeAccordionTab[0] ? 'active' : ''}`}
                // onClick={() => handleAccordionTab()}
              >
                <p>What if I received an error when trying to buy tickets?</p>
              </Accordion.Toggle>
              <Accordion.Collapse >
                    <p>We apologise for the inconvenience you have encountered with our online service. 
                    Before re-attempting your purchase, we recommend that you check your order status at www.starcinemas.ae/Theatre/Orderstatus.aspx. The credentials to login this page were emailed to you at your first booking attempt. If an order is placed, the order status will appear on the page just after you sign in. 
                    If no order appears in your Order History, we recommend that you then re-attempt your purchase. If you have questions regarding the purchase email our support team at customercare@starcinemas.ae</p>
                </Accordion.Collapse>
                </Card>
              </Accordion>
                  */}
            {/* <Accordion
              activeKey={activeAccordionTabIndex}
              defaultActiveKey={activeAccordionTabIndex}
              className="faq_new_accordion"
            >
              {filteredFaq.map((faq, index) => (
                <Card key={index}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${index}`}
                    className={`${activeAccordionTab[index] ? 'active' : ''}`}
                    onClick={() => handleAccordionTab(index)}
                  >
                    <p>
                      {faq.q} 
                      //({faq.categoryDisplay}) 
                    </p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${index}`}>
                    <p>{faq.a}</p>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion> */}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(FAQNewContainer);
